::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
::-webkit-scrollbar-thumb {
	background: linear-gradient(78deg, #f9d4ff 24%, #fcd553 90%);
	border-radius: 15px;
}

::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 15px;
	box-shadow: inset 7px 10px 12px transparent;
}
